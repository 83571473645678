import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../redux/app/hooks';
import {
    setActualTokenUsage,
    setAllocatedTokens,
    setInitialTokenPool,
    setPricePerToken,
    setTokenConsumers,
    setTokenPool,
} from '../../redux/features/admin/adminAppSlice';
import { setBudgetToConsumers } from '../../redux/features/admin/token-consumers/TokenConsumerSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { ITokenConsumer } from '../models/TokenUser';
import { AdminService } from '../services/AdminService';

export interface UseAdmin {
    initialize: () => Promise<boolean>;
    allocateBudget: (userId: string, tokensAllocated: number) => Promise<void>;
}

export const useAdmin = (): UseAdmin => {
    const adminService: AdminService = new AdminService(process.env.REACT_APP_BACKEND_URI as string);
    const dispatch = useAppDispatch();
    const { instance, inProgress } = useMsal();

    // const account = instance.acquireTokenByCode(instance.getActiveAccount);

    const initialize = async () => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        const users: ITokenConsumer[] = await adminService.getUsers(accessToken);
        const initialTokens = await adminService.getInitialTokenPoolAsync(accessToken);
        const pricePerToken = await adminService.getPricePerTokenAsync(accessToken);
        if (users.length > 0) {
            const allocateTokens: number = users.map((user) => user.budgetAllocation).reduce((a, b) => a + b, 0);
            const actualTokenUsage: number = users.map((user) => user.budgetUsage).reduce((a, b) => a + b, 0);
            const tokenPool: number = initialTokens - allocateTokens;
            dispatch(setPricePerToken(pricePerToken));
            dispatch(setTokenPool(tokenPool));
            dispatch(setInitialTokenPool(initialTokens));
            dispatch(setAllocatedTokens(allocateTokens));
            dispatch(setTokenConsumers(users));
            dispatch(setBudgetToConsumers({ consumers: users, pricePerToken: pricePerToken }));
            dispatch(setActualTokenUsage(actualTokenUsage));
            return true;
        }
        return false;
    };

    const allocateBudget = async (userId: string, tokensAllocated: number) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        void (await adminService.setBudgetAllocatedAsync(accessToken, userId, tokensAllocated));
    };

    return { initialize, allocateBudget };
};
