import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { GetResponseOptions } from '../../libs/hooks/useChat';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { Breakpoints } from '../../styles';
import { ChatStatus } from './ChatStatus';
import { InputWithSend } from './InputWithSend';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // ...shorthands.padding(tokens.spacingVerticalM),
        ...shorthands.margin('0', '40px'),
        width: '100%',
        minWidth: '140px',
        '@media (min-width: 1800px)': {
            marginLeft: '93px',
        },

        // ...shorthands.margin(tokens.spacingHorizontalXXXL),
    },
    typingIndicator: {
        maxHeight: '28px',
    },
    content: {
        ...shorthands.gap(tokens.spacingHorizontalS),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        display: 'flex',
        flexDirection: 'row',
        height: '55px',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    input: {
        ...shorthands.margin(tokens.spacingHorizontalS),
        width: '100%',
        minWidth: '140px',
        height: '100%',
        '@media(min-width: 768px)': {
            width: '50rem', // revert to 50rem width on larger screens (tablets and above)
        },
    },
    textarea: {
        maxHeight: '85px',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        alignContent: 'center',
        textAlignVertical: 'center',
        ...shorthands.padding(tokens.spacingHorizontalM),
    },
    controls: {
        display: 'flex',
        flexDirection: 'column',
        '@media(min-width: 768px)': {
            flexDirection: 'row',
        },
    },

    essentials: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    button: {
        marginLeft: '-50px',
        position: 'relative',
        height: '25px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: '140px',
    },
    tokenInfo: {
        // paddingLeft: '10px',
        // marginLeft: '13%',
        fontWeight: 'bold',
        // ...shorthands.flex('0 0 auto'),
        // textLeft: 'right',
        // textAlign: 'left',
        color: '#499678',
        '@media(min-width: 768px)': {},

        width: '100%',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',

        //paddingLeft: '18px',
        ...Breakpoints.small({
            marginLeft: '0',
            paddingLeft: '0',
        }),

        '& p': {
            width: '100%',

            ...shorthands.padding('0', '11.3%'),
            ...shorthands.margin('0', '12px'),
            ...Breakpoints.small({
                paddingLeft: '5%',
            }),
        },
    },
});

interface ChatInputProps {
    isDraggingOver?: boolean;
    onDragLeave: React.DragEventHandler<HTMLDivElement | HTMLTextAreaElement>;
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}

export const ChatInput: React.FC<ChatInputProps> = ({ isDraggingOver, onSubmit }) => {
    const classes = useClasses();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    // const { instance, inProgress } = useMsal();
    // const chat = useChat();

    // const [recognizer, setRecognizer] = React.useState<speechSdk.SpeechRecognizer>();
    // const [isListening, setIsListening] = React.useState(false);
    // const [documentImporting, setDocumentImporting] = React.useState(false);
    // const documentFileRef = useRef<HTMLInputElement | null>(null);
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);

    const percentUsed = Math.round(((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100);

    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    React.useEffect(() => {
        // Focus on the text area when the selected conversation changes
        textAreaRef.current?.focus();
    }, [selectedId, percentUsed]);

    // React.useEffect(() => {
    //     async function initSpeechRecognizer() {
    //         const speechService = new SpeechService(process.env.REACT_APP_BACKEND_URI as string);
    //         const response = await speechService.getSpeechTokenAsync(
    //             await AuthHelper.getSKaaSAccessToken(instance, inProgress),
    //         );
    //         if (response.isSuccess) {
    //             const recognizer = speechService.getSpeechRecognizerAsyncWithValidKey(response);
    //             setRecognizer(recognizer);
    //         }
    //     }

    //     initSpeechRecognizer().catch((e) => {
    //         const errorDetails = e instanceof Error ? e.message : String(e);
    //         const errorMessage = `Unable to initialize speech recognizer. Details: ${errorDetails}`;
    //         dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
    //     });
    // }, [dispatch, instance, inProgress]);

    // const handleSpeech = () => {
    //     setIsListening(true);
    //     if (recognizer) {
    //         recognizer.recognizeOnceAsync((result) => {
    //             if (result.reason === speechSdk.ResultReason.RecognizedSpeech) {
    //                 if (result.text && result.text.length > 0) {
    //                     handleSubmit(result.text);
    //                 }
    //             }
    //             setIsListening(false);
    //         });
    //     }
    // };

    // const handleImport = (dragAndDropFiles?: FileList) => {
    //     const files = dragAndDropFiles ?? documentFileRef.current?.files;

    //     if (files && files.length > 0) {
    //         setDocumentImporting(true);
    //         // Deep copy the FileList into an array so that the function
    //         // maintains a list of files to import before the import is complete.
    //         const filesArray = Array.from(files);
    //         void chat.importDocument(selectedId, filesArray).finally(() => {
    //             setDocumentImporting(false);
    //         });
    //     }

    //     // Reset the file input so that the onChange event will
    //     // be triggered even if the same file is selected again.
    //     if (documentFileRef.current?.value) {
    //         documentFileRef.current.value = '';
    //     }
    // };

    // const handleDrop = (e: React.DragEvent<HTMLTextAreaElement>) => {
    //     onDragLeave(e);
    //     handleImport(e.dataTransfer.files);
    // };

    return (
        <div className={classes.root}>
            <div className={classes.typingIndicator}>
                <ChatStatus />
            </div>
            {/* <Alerts /> */}
            <div className={classes.container}>
                <InputWithSend onSubmit={onSubmit} isDraggingOver={isDraggingOver} />
                {/* <div className={classes.tokenInfo}>
                    <p>
                        {Math.round(
                            ((activeUserInfo?.tokensUsed ?? 1) / (activeUserInfo?.tokensAllocated ?? 1)) * 100,
                        ) >= 100
                            ? ' מכסת השימוש נוצלה עד תומה. יש לפנות לנציגות המכללה להגדלת המכסה'
                            : `${activeUserInfo?.username},
                    ניצלת עד כה ${Math.round(
                        ((activeUserInfo?.tokensUsed ?? 1) / (activeUserInfo?.tokensAllocated ?? 1)) * 100,
                    )}% מחשבונך`}
                    </p>
                </div> */}
            </div>
            {/* <div className={classes.controls}>
                <div className={classes.functional}> */}
            {/* Hidden input for file upload. Only accept .txt and .pdf files for now. */}
            {/* <input
                        type="file"
                        ref={documentFileRef}
                        style={{ display: 'none' }}
                        accept=".txt,.pdf,.md,.jpg,.jpeg,.png,.tif,.tiff"
                        multiple={true}
                        onChange={() => {
                            handleImport();
                        }}
                    />
                    <Button
                        disabled={documentImporting}
                        appearance="transparent"
                        icon={<AttachRegular />}
                        onClick={() => documentFileRef.current?.click()}
                        title="Attach file"
                        aria-label="Attach file button"
                    /> */}
            {/* {documentImporting && <Spinner size="tiny" />} */}
            {/* </div>
                <div className={classes.essentials}> */}
            {/* {recognizer && (
                        <Button
                            appearance="transparent"
                            disabled={isListening}
                            icon={<MicRegular />}
                            onClick={handleSpeech}
                        />
                    )} */}
        </div>
        //     </div>
        // </div>
    );
};
