import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { FC } from 'react';
import { useChat } from '../../libs/hooks';
import { ChatSessionMode } from '../../libs/models/ChatSession';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { setConversationMode } from '../../redux/features/conversations/conversationsSlice';

export interface IPickerItemProps {
    text: string;
    subtext?: string;
    Icon: FC;
    mode: ChatSessionMode;
    isPreview?: boolean;
}

const usePickerItemStyles = makeStyles({
    card: {
        ...shorthands.padding('15px'),
        display: 'flex',
        height: '180px',
        width: '190px',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: 'white',
        ...shorthands.borderRadius('15px'),
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    text: {
        height: '35px',
        marginTop: '5px',
        marginBottom: '20px',
        fontSize: '0.9rem',
        justifySelf: 'center',
        alignSelf: 'center',
        fontWeight: tokens.fontWeightMedium,
        lineHeight: tokens.lineHeightBase300,
    },
    subtext: {
        textAlign: 'left',
        alignSelf: 'flex-start',
        marginTop: '5px',
        fontSize: '14px',
    },
});

export const PickerItem: FC<IPickerItemProps> = ({ text, subtext, Icon, mode, isPreview }) => {
    const chat = useChat();
    const styles = usePickerItemStyles();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const selectedChat = conversations[selectedId];
    const dispatch = useAppDispatch();

    const handleClick = async () => {
        dispatch(setConversationMode({ chatId: selectedId, mode: mode }));
        await chat.editChat(
            selectedId,
            selectedChat.title,
            new Date(selectedChat.createdOn),
            selectedChat.temperature,
            mode,
        );
    };

    return (
        <div
            className={styles.card}
            style={{
                animation: 'fadeIn 0.6s',
            }}
            onClick={() => void handleClick()}
        >
            <style>
                {`
                    @keyframes fadeIn {
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                    }
                `}
            </style>
            <Icon />

            <div className={styles.text}>{text}</div>
            <div className={styles.subtext}>{subtext}</div>
            {isPreview && (
                <div style={{ bottom: '0', left: '0', position: 'relative', fontSize: '10px' }}>*Preview</div>
            )}
        </div>
    );
};
