import { ITokenConsumer } from '../models/TokenUser';
import { BaseService } from './BaseService';

interface ITokensAllocateRequest {
    userId: string;
    budgetAllocated: number;
}

export class AdminService extends BaseService {
    public setBudgetAllocatedAsync = async (
        accessToken: string,
        userId: string,
        budgetAllocated: number,
    ): Promise<ITokenConsumer | string> => {
        try {
            const body: ITokensAllocateRequest = {
                userId: userId,
                budgetAllocated: budgetAllocated,
            };
            const result = await this.getResponseAsync<ITokenConsumer>(
                {
                    commandPath: `tokenConsumers/setBudgetAllocation`,
                    method: 'PUT',
                    body: body,
                },
                accessToken,
            );
            return result;
        } catch (error) {
            console.error(`Error setting tokens allocated for user ${userId}:`, error);
            return 'Error setting tokens allocated. Please try again later.';
        }
    };

    public getPricePerTokenAsync = async (accessToken: string): Promise<number> => {
        try {
            const result = await this.getResponseAsync<number>(
                {
                    commandPath: `tokenConsumers/pricePerToken`,
                    method: 'GET',
                },
                accessToken,
            );
            return result;
        } catch (error) {
            console.error(`Error fetching price per token:`, error);
            throw new Error('Error fetching price per token. Please try again later.');
        }
    };

    public getInitialTokenPoolAsync = async (accessToken: string): Promise<number> => {
        try {
            //return 5000000;
            const result = await this.getResponseAsync<{ baseAllocation: number }>(
                {
                    commandPath: `tokenConsumers/baseAllocation`,
                    method: 'GET',
                },
                accessToken,
            );
            return result.baseAllocation;
        } catch (error) {
            console.error(`Error fetching initial token pool:`, error);
            throw new Error('Error fetching initial token pool. Please try again later.');
        }
    };

    public getUsers = async (accessToken: string): Promise<ITokenConsumer[]> => {
        const result = await this.getResponseAsync<ITokenConsumer[]>(
            {
                commandPath: `tokenConsumers`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };
}
