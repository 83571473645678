import { makeStyles, SelectTabEventHandler, shorthands, Tab, TabList, TabValue } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { setParaphrasedText } from '../../redux/features/conversations/conversationsSlice';

const useClasses = makeStyles({
    container: {
        ...shorthands.gap('15px'),
        display: 'flex',
        alignItems: 'center',
    },

    textBoxHeaderTitle: {
        ...shorthands.margin(0),
        // ...shorthands.gap(tokens.spacingHorizontalL),
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '1.1rem',
        ':span': {
            marginLeft: '2px',
        },
    },
});

interface StylesTabProps {
    selectedTab: TabValue;
    setSelectedTab: (value: string) => void;
}

export const StylesTab: React.FC<StylesTabProps> = (props: StylesTabProps) => {
    const classes = useClasses();
    const { selectedTab, setSelectedTab } = props;
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const dispatch = useDispatch();

    const onTabSelect: SelectTabEventHandler = (_event, data) => {
        setSelectedTab(data.value as string);
    };

    const styleExplanations: Record<string, string> = {
        typos: 'סגנון תיקוני שגיאות כתיב - תיקון שגיאות כתיב תוך שמירה על המשמעות המקורית.',
        basic: 'סגנון בסיסי - ניסוח טקסט בצורה פשוטה וברורה, תוך שמירה על המשמעות המקורית.',
        short: 'סגנון תמציתי - ניסוח קצר וקולע, תוך שמירה על המשמעות המקורית.',
        rich: 'סגנון עשיר - ניסוח עשיר ומפורט, תוך שמירה על המשמעות המקורית.',
        strong: 'סגנון מחזק טענות - ניסוח אשר מחזק את הטענות המרכזיות תוך שמירה על המשמעות המקורית',
        translator: 'תרגום משפטי - תרגום משפטי תוך שמירה על המשמעות המקורית',
    };

    const getStyleExplanation = (style: string): string => {
        return styleExplanations[style] || '';
    };

    useEffect(() => {
        dispatch(
            setParaphrasedText({
                chatId: selectedId,
                paraphrasedText: getStyleExplanation(selectedTab as string),
            }),
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    return (
        <div className={classes.textBoxHeaderTitle}>
            <span>סגנון:</span>
            <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                <Tab
                    data-testid="styleTypos"
                    id="typos"
                    value="typos"
                    aria-label="paraphraseTypos"
                    title="תיקון שגיאות בלבד"
                >
                    תיקון שגיאות בלבד
                </Tab>
                <Tab
                    data-testid="styleBasic"
                    id="translator"
                    value="translator"
                    aria-label="paraphraserTranslator"
                    title="בסיסי"
                >
                    תרגום משפטי
                </Tab>
                <Tab
                    data-testid="styleBasic"
                    id="basic"
                    value="basic"
                    aria-label="paraphraserBasic"
                    title="תרגום משפטי"
                >
                    בסיסי
                </Tab>
                <Tab data-testid="styleShort" id="short" value="short" aria-label="paraphraserShort" title="תמציתי">
                    תמציתי
                </Tab>
                <Tab data-testid="styleRich" id="rich" value="rich" aria-label="paraphraserRich" title="עשיר">
                    עשיר
                </Tab>
                <Tab
                    data-testid="styleLegal"
                    id="strong"
                    value="strong"
                    aria-label="paraphraserLegal"
                    title="מחזק טענות"
                >
                    מחזק טענות
                </Tab>
            </TabList>
        </div>
    );
};
