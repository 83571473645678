import {
    Avatar,
    Input,
    InputOnChangeData,
    InteractionTag,
    InteractionTagPrimary,
    InteractionTagSecondary,
    Persona,
    makeStyles,
    shorthands,
    tokens,
    useId,
} from '@fluentui/react-components';
import { ChangeEvent, FC, useState } from 'react';
import { useGraph } from '../../../libs/hooks';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { UserData } from '../../../redux/features/users/UsersState';

interface IUsersPickerProps {
    selectedUsers: UserData[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<UserData[]>>;
}

const useClasses = makeStyles({
    root: {
        direction: 'rtl',
    },
    input: {
        width: '100%',
    },
    menuContainer: {
        position: 'absolute',
    },
    menu: {
        position: 'absolute',
        top: '-10px',
        listStyleType: 'none',
        backgroundColor: '#fff',
        ...shorthands.padding('8px'),
        minWidth: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('4px'),
        zIndex: 999,
        boxShadow: tokens.shadow16,
        ...shorthands.borderRadius('3px'),
    },
    menuItem: {
        ...shorthands.padding('3px'),
        cursor: 'pointer',
        '&:hover ': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
        },
        display: 'flex',
    },
    persona: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('8px'),
    },
    tag: {
        direction: 'ltr',
        ...shorthands.margin('2px'),
    },
    tagPrimary: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    tagContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '0.5rem',
    },
});

export const UsersPicker: FC<IUsersPickerProps> = ({ selectedUsers, setSelectedUsers }) => {
    const classes = useClasses();
    const [people, setPeople] = useState<UserData[]>([]);
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const msGraph = useGraph();
    const inputId = useId('input');
    const chatUsersIds = conversations[selectedId].users.map((participant) => participant.id.split('.')[0]);

    const searchUsersByName = async (searchTerm: string) => {
        const users = (await msGraph.searchUsersByName(searchTerm)) as UserData[];
        const selectedPeopleSet = new Set(selectedUsers.map((selectedUser) => selectedUser.id));
        chatUsersIds.forEach((chatUserId) => selectedPeopleSet.add(chatUserId));
        const filteredUsers = users.filter((u) => !selectedPeopleSet.has(u.id));
        setPeople(filteredUsers);
    };

    const handleChange = (_e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setValue(data.value);
        searchUsersByName(data.value)
            .then(() => {
                setIsMenuVisible(true);
            })
            .catch((_error) => {
                console.log('error', _error);
            });
    };

    const selectPerson = (person: UserData) => {
        //setSelectedPeople((prev) => [...prev, person]);
        setSelectedUsers((prev) => [...prev, person]);
        setIsMenuVisible(false);
        setValue('');
    };

    const removePerson = (id: string) => {
        // const people = selectedPeople.filter((p) => p.id !== id);
        // setSelectedPeople(people);
        const people = selectedUsers.filter((p) => p.id !== id);
        setSelectedUsers(people);
    };

    return (
        <div className={classes.root}>
            <Input
                className={classes.input}
                onChange={handleChange}
                value={value}
                id={inputId}
                placeholder="חיפוש לפי שם משתמש"
            />
            {isMenuVisible && people.length > 0 && (
                <div className={classes.menuContainer}>
                    <ul className={classes.menu}>
                        {people.map((person) => (
                            <li
                                className={classes.menuItem}
                                key={person.id}
                                onClick={() => {
                                    selectPerson(person);
                                }}
                            >
                                <Persona
                                    className={classes.persona}
                                    avatar={{ color: 'colorful' }}
                                    name={person.displayName}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {selectedUsers.length > 0 && (
                <div className={classes.tagContainer}>
                    {selectedUsers.map((selectedPerson) => (
                        <InteractionTag className={classes.tag} key={selectedPerson.id} shape="circular" size="small">
                            <InteractionTagPrimary
                                className={classes.tagPrimary}
                                media={<Avatar name={selectedPerson.displayName} color="colorful" />}
                            >
                                {selectedPerson.displayName}
                            </InteractionTagPrimary>
                            <InteractionTagSecondary
                                aria-label="remove"
                                onClick={() => {
                                    removePerson(selectedPerson.id);
                                }}
                            />
                        </InteractionTag>
                    ))}
                </div>
            )}
        </div>
    );
};
