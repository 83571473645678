import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

const useClasses = makeStyles({
    colorCubeContainer: {
        ...shorthands.gap('5px'),
        display: 'flex',
        alignItems: 'center',
    },
    colorCube: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: '15px',
        height: '15px',
    },
    colorText: {
        // Text styles, e.g., fontSize: '14px', marginLeft: '10px'
    },
});

interface HistoryCubeProps {
    color: string;
    title: string;
}

export const HistoryCube: React.FC<HistoryCubeProps> = (props: HistoryCubeProps) => {
    const classes = useClasses();
    const { color, title } = props;
    return (
        <div className={classes.colorCubeContainer}>
            <div className={classes.colorCube} style={{ backgroundColor: color }} />
            <span className={classes.colorText}>{title}</span>
        </div>
    );
};
