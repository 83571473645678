import { TemperatureType } from '../models/TemperatureType';
import { BaseService } from './BaseService';

interface IParaphraseRequest {
    text: string;
    style: string;
    chatId: string;
    temperature: TemperatureType;
    file?: string;
}

interface IParaphraseResponse {
    file?: Blob;
    paraphrasedText: string;
}

export class ParaphraserService extends BaseService {
    public getParaphrasedText = async (
        accessToken: string,
        text: string,
        style: string,
        chatId: string,
        temperature: TemperatureType,
        file?: File,
    ): Promise<IParaphraseResponse | undefined> => {
        try {
            const base64EncodedFile = file ? await this.encodeFileToBase64(file) : undefined;
            const body: IParaphraseRequest = {
                text,
                style,
                chatId,
                temperature,
                file: base64EncodedFile,
            };
            return await this.getResponseAsync<IParaphraseResponse>(
                {
                    commandPath: `Paraphrase`,
                    method: 'POST',
                    body: body,
                },
                accessToken,
            );
        } catch (error) {
            console.error(`Error getting paraphrased response:`, error);
            return undefined;
        }
    };

    public handleWordDoc = async (
        document: File,
        style: string,
        chatId: string,
        accessToken: string,
    ): Promise<Blob> => {
        const base64EncodedFile = await this.encodeFileToBase64(document);
        const body: IParaphraseRequest = {
            text: '',
            style: style,
            chatId: chatId,
            temperature: TemperatureType.Creative,
            file: base64EncodedFile,
        };
        return await this.getResponseAsync<Blob>(
            {
                commandPath: `Paraphrase/handleWordDoc`,
                method: 'POST',
                body: body,
            },
            accessToken,
        );
    };

    private async encodeFileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const result = reader.result as string;
                resolve(result.split(',')[1]); // Remove the "data:<type>;base64," prefix if needed
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file); // Reads the file as a base64 encoded string
        });
    }
}
