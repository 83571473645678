import { makeStyles } from '@fluentui/react-components';
import {
    DocumentBulletListCube24Regular,
    DocumentOnePageSparkle24Regular,
    TextProofingTools24Regular,
} from '@fluentui/react-icons';
import { FC } from 'react';
import { ChatSessionMode } from '../../libs/models/ChatSession';
import { BingLogo } from './BingLogo';
import { OpenaiLogo } from './OpenAILogo';
import { PickerGrid } from './PickerGrid';

const useClasses = makeStyles({
    modePicker: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
});

export const ModePicker: FC = () => {
    const classes = useClasses();
    return (
        <div className={classes.modePicker}>
            <PickerGrid
                items={[
                    {
                        text: 'שיחה כללית עם הAI',
                        subtext:
                            'אינטראקציה כללית עם AI למגוון רחב של נושאים ושאלות. מתאים לשיחה כללית ולשאילת שאלות על נושאים שונים.',
                        Icon: OpenaiLogo,
                        mode: ChatSessionMode.CHAT,
                    },
                    {
                        text: 'שיחה עם שימוש במנוע חיפוש בינג',
                        subtext:
                            'חיפוש ודיון עם שימוש במנוע החיפוש של בינג לקבלת מידע עדכני ומקיף. אידיאלי למציאת עובדות ומידע בזמן אמת.',
                        Icon: BingLogo,
                        mode: ChatSessionMode.BING,
                    },
                    {
                        text: 'ניסוח והגהה',
                        subtext: 'ניסוח מחדש, תרגום, עריכה והגהה של טקסטים לקבלת תוצאה מקצועית ומדויקת.',
                        Icon: TextProofingTools24Regular,
                        mode: ChatSessionMode.PARAPHRASER,
                    },
                    {
                        text: 'העלאת מסמך ותחקור התוכן',
                        subtext: 'העלאת מסמכים קיימים וחקירת התוכן בעזרת AI לצורך קבלת תובנות והסברים מעמיקים.',
                        Icon: DocumentOnePageSparkle24Regular,
                        mode: ChatSessionMode.QNA,
                    },
                    {
                        text: 'יצירת מסמך משפטי',
                        subtext: 'הפקת מסמכים משפטיים על בסיס תבניות מוכנות מראש, להתאמה אישית וקבלת תוצאה מקצועית.',
                        Icon: DocumentBulletListCube24Regular,
                        mode: ChatSessionMode.DOCUMENTGENERATION,
                        isPreview: true,
                    },
                ]}
            />
        </div>
    );
};
