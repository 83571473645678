import { Button, Spinner, Textarea, Tooltip, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { DocumentArrowUp16Regular, Send24Filled, Send24Regular } from '@fluentui/react-icons';
import { log } from 'debug';
import React, { useRef } from 'react';
import { GetResponseOptions } from '../../libs/hooks/useChat';
// import { AlertType } from '../../libs/models/AlertType';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
// import { addAlert } from '../../redux/features/app/appSlice';
import {
    editConversationInput,
    updateBotResponseStatus,
    updateUserIsTyping,
} from '../../redux/features/conversations/conversationsSlice';
//import { Breakpoints } from '../../styles';
import { Constants } from '../../Constants';
import { useFile } from '../../libs/hooks';
import { ChatSessionMode } from '../../libs/models/ChatSession';
import { Breakpoints } from '../../styles';

interface IInputWithSend {
    isDraggingOver?: boolean;
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '85%',
        // ...shorthands.padding('10px', '10px', '10px', '10px'),
        '@media (min-width: 1800px)': {
            // ...shorthands.padding('12px', '12px', '36px', '12px'),
        },
    },
    buttonWithSpinner: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalM),
        flexDirection: 'row',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        alignItems: 'center',
    },
    input: {
        ...shorthands.padding('0px', '35px', '0px', '15px'),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '70px',
        textAlign: 'center',
        height: '100%',
        resize: 'none',
        // paddingTop: '30px',
        width: '100%',
        ...shorthands.borderRadius(tokens.borderRadiusCircular),
        ...shorthands.border('none'),
        ...shorthands.border(tokens.strokeWidthThin, ' solid', tokens.colorNeutralStroke1Pressed),
        '&:placeholder': {
            backgroundColor: 'red',
        },
        ':hover': {
            ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorNeutralStroke1Pressed),
        },
        ':active': {
            ...shorthands.borderBottom(tokens.strokeWidthThick, ' solid', tokens.colorNeutralStroke1Pressed),
        },
        ':focus-within': {
            ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
            ':after': {
                display: 'none',
            },
        },
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    typingIndicator: {
        maxHeight: '28px',
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        // ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    button: {
        right: '1rem',
        // width: '5rem',
        // height: '3rem',
        position: 'absolute',
        ...shorthands.borderRadius(tokens.borderRadiusCircular),
    },
    uploadButton: {
        // width: 'fit-content',
        alignSelf: 'start',
        justifySelf: 'start',
        marginLeft: '1rem',
        width: 'content-fit',
        display: 'flex',
        marginBottom: '0.5rem',
    },
    // uploadButtonWrapper: {
    //     width: 'content-fit',
    //     display: 'flex',
    //     marginBottom: '0.5rem',
    // },
    tokenInfo: {
        fontWeight: 'bold',
        color: 'black',
        '@media(min-width: 768px)': {},

        width: '100%',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        ...Breakpoints.small({
            marginLeft: '0',
            paddingLeft: '0',
        }),

        '& p': {
            width: '100%',

            // ...shorthands.padding('0', '11.3%'),
            // ...shorthands.margin('0', '12px'),
            ...Breakpoints.small({
                // paddingLeft: '5%',
            }),
        },
    },
});

export const InputWithSend: React.FC<IInputWithSend> = ({ isDraggingOver, onSubmit }) => {
    const classes = useClasses();
    const [value, setValue] = React.useState('');
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const dispatch = useAppDispatch();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const percentUsed = Math.round(((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100);
    const localDocumentFileRef = useRef<HTMLInputElement | null>(null);
    const fileHandler = useFile();
    const { mode } = conversations[selectedId];

    const { importingDocuments } = conversations[selectedId];

    React.useEffect(() => {
        const chatState = conversations[selectedId];
        setValue(chatState.input);
    }, [conversations, selectedId, percentUsed]);

    const message = conversations[selectedId].botResponseStatus;
    const handleSubmit = (value: string, messageType: ChatMessageType = ChatMessageType.Message) => {
        const temperature = conversations[selectedId].temperature;
        if (value.trim() === '') {
            return; // only submit if value is not empty
        }

        setValue('');
        dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'מתחבר...' }));
        onSubmit({ value, messageType, chatId: selectedId, temperature }).catch((error) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            log(message);
            // dispatch(
            //     addAlert({
            //         type: AlertType.Error,
            //         message,
            //     }),
            // );
        });
    };

    return (
        <div className={classes.root}>
            <input
                type="file"
                ref={localDocumentFileRef}
                style={{ display: 'none' }}
                accept={Constants.app.importTypes}
                multiple={false}
                onChange={() => {
                    void fileHandler.handleImport(selectedId, localDocumentFileRef, false);
                }}
            />

            {(mode === ChatSessionMode.QNA || mode === ChatSessionMode.ALL) && (
                <div className={classes.buttonWithSpinner}>
                    <Tooltip content="העלאת מסמך לתחקור ותשאול" relationship="label">
                        <Button
                            className={classes.uploadButton}
                            // icon={<DocumentArrowUp20Regular />}
                            icon={
                                importingDocuments && importingDocuments.length > 0 ? (
                                    <Spinner size="tiny" appearance="inverted" />
                                ) : (
                                    <DocumentArrowUp16Regular />
                                )
                            }
                            disabled={percentUsed >= 100}
                            appearance="primary"
                            style={{ borderRadius: '7px' }}
                            onClick={() => localDocumentFileRef.current?.click()}
                        >
                            העלאת מסמך
                        </Button>
                    </Tooltip>
                </div>
            )}
            <div className={classes.content}>
                <Textarea
                    disabled={percentUsed >= 100}
                    placeholder="שליחת הודעה"
                    // as="textarea"
                    size="large"
                    aria-label="Chat input field. Click enter to submit input."
                    ref={textAreaRef}
                    id="chat-input"
                    appearance="outline"
                    // textarea={{
                    //     className: isDraggingOver
                    //         ? mergeClasses(classes.dragAndDrop, classes.textarea)
                    //         : classes.textarea,
                    // }}
                    // textarea={{ className: classes.textarea }}
                    className={classes.input}
                    value={value}
                    // onDrop={handleDrop}
                    onFocus={() => {
                        // update the locally stored value to the current value
                        const chatInput = document.getElementById('chat-input');
                        if (chatInput) {
                            setValue((chatInput as HTMLTextAreaElement).value);
                        }
                        // User is considered typing if the input is in focus

                        activeUserInfo?.id &&
                            dispatch(
                                updateUserIsTyping({
                                    userId: activeUserInfo.id,
                                    chatId: selectedId,
                                    isTyping: true,
                                }),
                            );
                    }}
                    onChange={(_event, data) => {
                        if (isDraggingOver) {
                            return;
                        }

                        setValue(data.value);
                        dispatch(editConversationInput({ id: selectedId, newInput: data.value }));
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                            event.preventDefault();
                            if (message == undefined) {
                                handleSubmit(value);
                            }
                        }
                    }}
                    onBlur={() => {
                        // User is considered not typing if the input is not  in focus
                        activeUserInfo?.id &&
                            dispatch(
                                updateUserIsTyping({
                                    userId: activeUserInfo.id,
                                    chatId: selectedId,
                                    isTyping: false,
                                }),
                            );
                    }}
                />
                <Button
                    title="שלח"
                    aria-label="Submit message"
                    appearance={value.trim() === '' ? 'transparent' : 'primary'}
                    disabled={value.trim() === '' || message != undefined}
                    className={classes.button}
                    shape="circular"
                    style={{
                        pointerEvents: value.trim() === '' ? 'none' : 'auto',
                    }}
                    size="medium"
                    icon={value.trim() === '' ? <Send24Regular /> : <Send24Filled />}
                    onClick={() => {
                        handleSubmit(value);
                    }}
                />
            </div>
            <div className={classes.tokenInfo}>
                <p>
                    {Math.round(((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100) >=
                    100
                        ? ' מכסת השימוש נוצלה עד תומה.'
                        : `${activeUserInfo?.username},
                    ניצלת עד כה ${Math.round(
                        ((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100,
                    )}% מחשבונך`}
                </p>
            </div>
        </div>
    );
};
