import { Body1, Caption1, ProgressBar, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { FC } from 'react';
import { ITokenConsumer } from '../../libs/models/TokenUser';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

const useClasses = makeStyles({
    usageBar: {
        ...shorthands.gap(tokens.spacingHorizontalL),
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        width: '60%',
    },
});

interface TokenPercentageUsageProps {
    user: ITokenConsumer;
}

export const TokenPercentageUsage: FC<TokenPercentageUsageProps> = (props: TokenPercentageUsageProps) => {
    const classes = useClasses();
    const budgetForUser = useAppSelector((state: RootState) => state.tokenConsumers[props.user.id].budgetForUser);

    const percentageUsed = Math.round((100 / budgetForUser) * props.user.budgetUsage) || 0;
    return (
        <div className={classes.usageBar}>
            <div style={{ width: '100%' }}>
                <Body1>{budgetForUser.toFixed(2) + ' / ' + props.user.budgetUsage.toFixed(2)}</Body1>
                <ProgressBar thickness="large" max={budgetForUser || 1} value={props.user.budgetUsage} />
            </div>
            <Caption1>{percentageUsed + '%'}</Caption1>
        </div>
    );
};
