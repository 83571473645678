import { makeStyles, shorthands } from '@fluentui/react-components';
import { HistoryCube } from './HistoryCube';

const useClasses = makeStyles({
    container: {
        ...shorthands.gap('15px'),
        display: 'flex',
        alignItems: 'center',
    },
});

export const HistoryCard: React.FC = () => {
    const classes = useClasses();
    const colors = ['#EED202', '#00c1fc'];
    const texts = ['ניסוח מחדש', 'תיקון שגיאות כתיב'];
    return (
        <div className={classes.container}>
            {colors.map((color, index) => (
                <HistoryCube key={index} color={color} title={texts[index]} />
            ))}
        </div>
    );
};
