import { Button, Tooltip, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';

import React from 'react';
import { TemperatureType } from '../../libs/models/TemperatureType';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatState } from '../../redux/features/conversations/ChatState';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: '14px',
        fontWeight: '400',
    },
    optionsContainer: {
        display: 'flex',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0.3px 0.9px 0px, rgba(0, 0, 0, 0.16) 0px 1.6px 3.6px 0px',
        ...shorthands.padding('4px'),
        ...shorthands.borderRadius('8px'),
        ...shorthands.gap(tokens.spacingHorizontalXS),
    },
    option: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.border('none'),
        '&:hover': {
            backgroundColor: 'unset',
        },
        '&:active': {
            backgroundColor: '#fff !important',
            color: 'transparent',
        },

        '& span': {
            fontSize: '10px',
        },
    },
    creative: {
        backgroundColor: '#0a7dd6',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0a7dd6',
            color: '#fff',
        },
        '&:active': {
            backgroundColor: '#0a7dd6 !important',
            color: '#fff !important',
        },
    },
    balanced: {
        backgroundColor: tokens.colorBrandBackground,
        color: '#fff',
        '&:hover': {
            backgroundColor: tokens.colorBrandBackground,
            color: '#fff',
        },
        '&:active': {
            backgroundColor: tokens.colorBrandBackground + ' !important',
            color: '#fff !important',
        },
    },
    precise: {
        backgroundColor: '#D43F3E',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#D43F3E',
            color: '#fff',
        },
        '&:active': {
            backgroundColor: '#D43F3E !important',
            color: '#fff !important',
        },
    },
});

interface ConversationStyleProps {
    onUpdateTemperature: (temperature: TemperatureType) => Promise<void>;
}

export const ConversationTemperature: React.FC<ConversationStyleProps> = ({ onUpdateTemperature }) => {
    const classes = useClasses();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chatState: ChatState = conversations[selectedId];
    const temperature: TemperatureType = chatState.temperature as TemperatureType;

    return (
        <div className={classes.root}>
            <div className={classes.optionsContainer}>
                <Tooltip withArrow content="שיחה יצירתית" relationship="label">
                    <Button
                        className={
                            TemperatureType.Creative === temperature
                                ? mergeClasses(classes.option, classes.creative)
                                : classes.option
                        }
                        onClick={() => void onUpdateTemperature(TemperatureType.Creative)}
                    >
                        יצירתי
                    </Button>
                </Tooltip>
                <Tooltip withArrow content="שיחה מאוזנת מבחינת יצירתיות" relationship="label">
                    <Button
                        className={
                            TemperatureType.Balanced === temperature
                                ? mergeClasses(classes.option, classes.balanced)
                                : classes.option
                        }
                        onClick={() => void onUpdateTemperature(TemperatureType.Balanced)}
                    >
                        מאוזן
                    </Button>
                </Tooltip>
                <Tooltip withArrow content="שיחה מדויקת כמעט ללא יצירתיות" relationship="label">
                    <Button
                        className={
                            TemperatureType.Precise === temperature
                                ? mergeClasses(classes.option, classes.precise)
                                : classes.option
                        }
                        onClick={() => void onUpdateTemperature(TemperatureType.Precise)}
                    >
                        מדוייק
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};
